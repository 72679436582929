/**
* Generated automatically at built-time (2025-02-10T09:02:15.104Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "weinkuehlschrank-black-friday",templateKey: "sites/70-bdb1685c-0209-45ce-8867-2e0b333c8114"};